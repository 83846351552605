<template>
  <!-- 头部 -->
  <div class="header">
    <!-- logo和搜索框 -->
    <div class="top">
      <div class="wrapper">
        <div class="left" @click="toHome">
          <img src="@/assets/yxtx/blbhLogo.png" alt="" class="header_Logo"/>
        </div>
        <div class="right">
          <!-- <input type="text">
          <p>
            <i class="icon iconfont icon-sousuo "></i>
          </p> -->
          <!--            <div class="search">-->
          <!--              <el-input-->
          <!--              placeholder="请输入关键词"-->
          <!--              class="input-with-select"-->
          <!--              >-->
          <!--              <el-button slot="append" icon="el-icon-search"></el-button>-->
          <!--              </el-input>-->
          <!--            </div>-->
          <!--            <div class="title">-->
          <!--              <a href="">校园网主页</a>-->
          <!--            </div>-->
        </div>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="bottom" style="display: none">
      <div class="wrapper">
        <ul>
          <li @click='$router.push("/home")'>首页</li>
          <!--            <li @click='toArticle(item.category)' :class='$route.query.name===item.category.name?"current":""'-->
          <!--                v-for="item in categories" :key='item.id'>-->
          <!--              {{ item.category.name }}-->
          <!--            </li>-->
        </ul>
        <hr>
      </div>
    </div>
  </div>
  <!-- /头部 -->
</template>

<script>

export default {
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    toHome() {
      console.log(this.$router)
      this.$router.replace({
        path: "/Index",
      })
    },
    toArticle(item) {
      // 编程式导航跳转
      this.$router.push({
        path: "/article",
        query: item
      })
    }
  },
  created() {
  }
}
</script>

<style lang='scss'>

// 头部
.header {
  .top {
    height: 100px;
    background-color: #ffffff;
    border-bottom: 1px solid #fafafa;
    //background-image: linear-gradient(185deg, #ffffff , #f1f1f1);

    .wrapper {
      display: flex;
      // 设置主轴排列方式
      justify-content: space-between;
      color: white;
      // line-height: 100px;
      .left {
        padding-top: 20px;
      }

      .right {
        padding-top: 30px;
        display: flex;

        .search {
          width: 377px;
          margin-right: 50px;
        }

        .title {
          padding-top: 10px;

          a {
            color: white;
          }

          a:hover {
            text-decoration: underline
          }
        }
      }
    }
  }

  .header_Logo {
    width: 70px;
  }

  .bottom {
    .wrapper {
      ul {
        display: flex;
        justify-content: space-evenly;
        width: 50%;
        height: 58px;
        padding: 0 20px;

        li {
          font-size: 17px;
          line-height: 60px;
          color: black;
          cursor: pointer;
        }

        li:hover {
          color: #be5b24;
          border-bottom: 2px solid #be5b24;
        }
      }

      hr {
        border: 1px solid #be5b24;
      }
    }
  }
}

// /头部
@media only screen and (max-width: 1000px) {
  .header {
    .top {
      height: 100px;
    }

    .header_Logo {
      width: 80px;
      margin-left: 20%;
    }
  }
}
</style>
